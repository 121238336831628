import { RouteRecordRaw, Router } from "vue-router"
import Index from '../views/dg6789admin6868/Index.vue'
import routesExample from "./example"
import client from "./client"
const routesAdmin: Array<RouteRecordRaw> = [
  // routesExample,
  {
    path: '/home',
    name: 'home',
    component: Index,
    meta: {
      requiresAuth: true,
      breadcrumb: [{ '/admin': 'Home' }]
    }
    ,
    children: [
      {
        path: '/index',
        name: 'index',
        component: () =>
          import('../views/admin/dashboard/Dashboard.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Danh sách phòng', status: false }]
        },
      }, {
        path: '/userlist',
        name: 'userlist',
        component: () =>
          import('../views/admin/user/UserList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/user', name: 'Người sử dụng', status: false }]
        },
      }, {
        path: '/informAdmin',
        name: 'informAdmin',
        component: () =>
          import('../views/admin/user/UserInform.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client' }]
        }
      }, {
        path: '/user-detail',
        name: 'user-detail',
        component: () =>
          import('../views/admin/user/UserDetail.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client' }]
        }
      }, {
        path: '/user-create',
        name: 'user-create',
        component: () =>
          import('../views/admin/user/UserCreate.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client' }]
        }
      },{
        path: '/user-views',
        name: 'user-views',
        component: () =>
          import('../views/admin/user/UserInformView.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client' }]
        }
      },
      
      {
        path: '/change-password',
        name: 'change-password',
        component: () =>
          import('../views/admin/user/ChangePassword.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ '/home': 'Home', '/user': 'Người sử dung', '': 'Thay đổi mật khẩu' }]
        }
      },
      {
        path: '/blogs',
        name: 'blogs',
        component: () =>
          import('../views/admin/blogs/BlogList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/blogs', name: 'Bài viết hướng dẫn', status: false }]
        },
      },{
        path: '/analytics',
        name: 'analytics',
        component: () =>
          import('../views/admin/analytics/Analytics.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/blogs', name: 'Bài viết hướng dẫn', status: false }]
        },
      }, {
        path: '/blogs-create',
        name: 'blogs-create',
        component: () =>
          import('../views/admin/blogs/BlogCreate.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/blogs', name: 'Tạo mới', status: false }]
        },
      }, {
        path: '/blogs-detail',
        name: 'blogs-detail',
        component: () =>
          import('../views/admin/blogs/BlogDetail.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/blogs', name: 'Chi tiết', status: false }]
        },
      }, {
        path: '/blogs-view',
        name: 'blogs-view',
        component: () =>
          import('../views/admin/blogs/BlogView.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/blogs', name: 'Xem', status: false }]
        },
      }, {
        path: '/blog',
        name: 'blog',
        component: () =>
          import('../views/admin/banner/BlogList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/blog', name: 'Bài viết', status: false }]
        },
      }, {
        path: '/blog-create',
        name: 'blog-create',
        component: () =>
          import('../views/admin/banner/BlogCreate.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/blog', name: 'Bài viết', status: false }]
        },
      }, {
        path: '/blog-detail',
        name: 'blog-detail',
        component: () =>
          import('../views/admin/banner/BlogDetail.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/blog', name: 'Bài viết', status: false }]
        },
      }, {
        path: '/blog-view',
        name: 'blog-view',
        component: () =>
          import('../views/admin/banner/BlogView.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/blog', name: 'Bài viết', status: false }]
        },
      }, {
        path: '/admin-roulette',
        name: 'admin-roulette',
        component: () =>
          import('../views/admin/roulette/ViRoultte.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/admin-roulette', name: 'Người sử dụng', status: false }]
        },
      }, {
        path: '/approved',
        name: 'approved',
        component: () =>
          import('../views/admin/approved/Approved.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/approved', name: 'Phê duyệt', status: false }]
        },
      }
      , {
        path: '/dashboard',
        name: 'dashboard',
        component: () =>
          import('../views/admin/dashboard/Dashboard.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Tổng quan', status: false }]
        },
      }, {
        path: '/academys',
        name: 'academys',
        component: () =>
          import('../views/admin/academy/Academy.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Học viện', status: false }]
        },
      }, {
        path: '/contructor',
        name: 'contructor',
        component: () =>
          import('../views/admin/contructor/Contructor.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Nhà thầu', status: false }]
        },
      }, {
        path: '/admin-news',
        name: 'admin-news',
        component: () =>
          import('../views/admin/blogs/BlogList.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Tin tức', status: false }]
        },
      }, {
        path: '/admin-products',
        name: 'admin-products',
        component: () =>
          import('../views/admin/product/Product.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Sản phẩm', status: false }]
        },
      }, {
        path: '/product-create',
        name: 'product-create',
        component: () =>
          import('../views/admin/product/ProductCreate.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Sản phẩm', status: false }]
        },
      }, {
        path: '/admin-products-detail',
        name: 'admin-products-detail',
        component: () =>
          import('../views/admin/product/ProductDetail.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Sản phẩm', status: false }]
        },
      },  {
        path: '/promotion',
        name: 'promotion',
        component: () =>
          import('../views/admin/promotion/Promotion.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Khuyến mãi', status: false }]
        },
      }, {
        path: '/promotion-create',
        name: 'promotion-create',
        component: () =>
          import('../views/admin/promotion/PromotionCreate.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Khuyến mãi', status: false }]
        },
      }, {
        path: '/promotion-edit',
        name: 'promotion-edit',
        component: () =>
          import('../views/admin/promotion/PromotionDetail.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Khuyến mãi', status: false }]
        },
      }, {
        path: '/report',
        name: 'report',
        component: () =>
          import('../views/admin/report/Report.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Báo cáo', status: false }]
        },
      }, {
        path: '/point',
        name: 'point',
        component: () =>
          import('../views/admin/save_point/Point.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Tích điểm', status: false }]
        },
      }, {
        path: '/point-detail',
        name: 'point-detail',
        component: () =>
          import('../views/admin/save_point/PointDetail.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Tích điểm', status: false }]
        },
      }, {
        path: '/gifts',
        name: 'gifts',
        component: () =>
          import('../views/admin/gift/GiftList.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Đổi quà', status: false }]
        },
      }, {
        path: '/gift-create',
        name: 'gift-create',
        component: () =>
          import('../views/admin/gift/GiftCreate.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Đổi quà', status: false }]
        },
      }, {
        path: '/gift-detail',
        name: 'gift-detail',
        component: () =>
          import('../views/admin/gift/GiftDetail.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Đổi quà', status: false }]
        },
      }, {
        path: '/his-gift',
        name: 'his-gift',
        component: () =>
          import('../views/admin/history/HistoryGift.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Lịch sử', status: false }]
        },
      }, {
        path: '/his-wheel',
        name: 'his-wheel',
        component: () =>
          import('../views/admin/history/HistoryWheel.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Lịch sử', status: false }]
        },
      }, {
        path: '/his-point',
        name: 'his-point',
        component: () =>
          import('../views/admin/history/HistoryPoint.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Lịch sử', status: false }]
        },
      }, {
        path: '/his-register',
        name: 'his-register',
        component: () =>
          import('../views/admin/history/HistoryRegister.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Lịch sử', status: false }]
        },
      }, {
        path: '/gift-register',
        name: 'gift-register',
        component: () =>
          import('../views/admin/gift_approve/GiftApproved.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'Đổi quà', status: false }]
        },
      }, {
        path: '/zalo-log',
        name: 'zalo-log',
        component: () =>
          import('../views/admin/log/ZaloLog.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'tin nhắn zalo', status: false }]
        },
      }, {
        path: '/zaloToken',
        name: 'zaloToken',
        component: () =>
          import('../views/admin/log/ZaloToken.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'tin nhắn zalo', status: false }]
        },
      }, {
        path: '/feedback',
        name: 'feedback',
        component: () =>
          import('../views/admin/feedback/Feedback.vue'),
        meta: {
          requiresAuth: false,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '', name: 'tin nhắn', status: false }]
        },
      },
      {
        path: '/admin-suport',
        name: 'admin-suport',
        component: () =>
          import('../views/admin/blog/BlogList.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/admin-suport', name: 'Bài viết hướng dẫn', status: false }]
        },
      }, {
        path: '/suport-create',
        name: 'suport-create',
        component: () =>
          import('../views/admin/blog/BlogCreate.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/admin-suport', name: 'Bài viết hướng dẫn', status: false }]
        },
      }, {
        path: '/suport-detail',
        name: 'suport-detail',
        component: () =>
          import('../views/admin/blog/BlogDetail.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/admin-suport', name: 'Bài viết hướng dẫn', status: false }]
        },
      }, {
        path: '/suport-view',
        name: 'suport-view',
        component: () =>
          import('../views/admin/blog/BlogView.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ url: '/', name: 'Trang chủ', status: true }, { url: '/admin-suport', name: 'Bài viết hướng dẫn', status: false }]
        },
      }
    ]

  },
  client,
  {
    path: '/:pathMatch(.*)*',
    name: '*',
    component: () =>
      import('../views/404.vue'),
    meta: {
      requiresUnauth: true
    }
  }

]
export default routesAdmin
